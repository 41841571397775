/* eslint-disable react/prop-types */
import React from 'react';

import { getMessages } from '../../locales';
import Quotes from '../common/Quotes/Quotes';

const QuotesSection = () => {
  const messages = getMessages();

  return (
    <section className="quotes-section">
      <div className="container">
        <div className="quotes-section-description">
          <h3 className="title-lg">{messages['quoteSection.title']}</h3>
        </div>
        <Quotes quotes={messages['quoteSection_quotes']}  />
      </div>
    </section>
  );
};

export default QuotesSection;
